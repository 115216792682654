import { defaultBodyStyle } from './clubMembers';

export const attemptOptions = ['OK', 'Foul', 'No throw'];

export const trackEventColumns = [
  {
    accessor: 'athleteNumber',
    label: 'Club ID',
    headerStyle: {
      fontSize: 13,
      paddingLeft: 21,
      minWidth: 154,
      letterSpacing: -0.3,
    },
    style: {
      fontSize: 13,
      paddingLeft: 21,
      minWidth: 154,
    },
  },
  {
    accessor: 'athleteName',
    label: 'Name',
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'athleteGender',
    label: 'Gender',
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'athleteAge',
    label: 'Age',
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'position',
    label: 'Position',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 5,
      minWidth: 58,
    },
    style: {
      paddingLeft: 22,
      minWidth: 58,
    },
  },
  {
    accessor: 'resultValue',
    label: 'Result',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 5,
      minWidth: 58,
    },
    style: {
      paddingLeft: 22,
      minWidth: 58,
    },
  },
  {
    accessor: 'pr',
    label: 'PR',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 10,
    },
    style: {
      paddingLeft: 22,
    },
  },
  {
    accessor: 'status',
    label: 'Status',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 10,
    },
    style: {
      paddingLeft: 22,
    },
  },
];

export const fieldEventColumns = (attemptNumber) => {
  return [
    {
      accessor: 'athleteNumber',
      label: 'Club ID',
      headerStyle: {
        fontSize: 13,
        paddingLeft: 21,
        minWidth: 154,
        letterSpacing: -0.3,
      },
      style: {
        fontSize: 13,
        paddingLeft: 21,
        minWidth: 154,
      },
    },
    {
      accessor: 'athleteName',
      label: 'Name',
      headerStyle: {
        paddingLeft: 20,
        paddingRight: 25,
        justifyContent: 'space-between',
      },
      style: {
        paddingLeft: 20,
      },
    },
    {
      accessor: 'athleteGender',
      label: 'Gender',
      headerStyle: {
        paddingLeft: 20,
        paddingRight: 25,
        justifyContent: 'space-between',
      },
      style: {
        paddingLeft: 20,
      },
    },
    {
      accessor: 'athleteAge',
      label: 'Age',
      headerStyle: {
        paddingLeft: 20,
        paddingRight: 25,
        justifyContent: 'space-between',
      },
      style: {
        paddingLeft: 20,
      },
    },
    {
      accessor: 'position',
      label: 'Position',
      checkbox: false,
      sortable: false,
      headerStyle: {
        paddingLeft: 22,
        paddingRight: 5,
        minWidth: 58,
      },
      style: {
        paddingLeft: 22,
        minWidth: 58,
      },
    },
    {
      accessor: 'result',
      label: 'Result',
      checkbox: false,
      sortable: false,
      headerStyle: {
        paddingLeft: 22,
        paddingRight: 5,
        minWidth: 58,
      },
      style: {
        paddingLeft: 22,
        minWidth: 58,
      },
    },
    {
      accessor: 'pr',
      label: 'PR',
      checkbox: false,
      sortable: false,
      headerStyle: {
        paddingLeft: 22,
        paddingRight: 10,
      },
      style: {
        paddingLeft: 22,
      },
    },
    {
      accessor: 'attempts',
      label: '',
      checkbox: false,
      sortable: false,
      headerStyle: {
        paddingLeft: 22,
        paddingRight: 10,
      },
      style: {
        display: 'grid',
        gridTemplateColumns: `repeat(${attemptNumber}, 1fr)`,
      },
    },
  ];
};

export const editTrackEventColumns = [
  {
    accessor: 'athleteNumber',
    label: 'Club ID',
    headerStyle: {
      fontSize: 13,
      paddingLeft: 21,
      minWidth: 154,
      letterSpacing: -0.3,
    },
    style: {
      fontSize: 13,
      paddingLeft: 21,
      minWidth: 154,
    },
  },
  {
    accessor: 'athleteName',
    label: 'Name',
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'athleteGender',
    label: 'Gender',
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'athleteAge',
    label: 'Age',
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'resultValue',
    label: 'Result (min:sec.ms)',
    checkbox: false,
    sortable: false,
    editable: true,
    editType: 'text',
    headerStyle: {
      paddingLeft: 10,
      paddingRight: 5,
      minWidth: 58,
    },
    style: {
      paddingLeft: 0,
      paddingRight: 10,
      minWidth: 58,
    },
  },
  {
    accessor: 'pr',
    label: 'PR',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 10,
    },
    style: {
      paddingLeft: 22,
    },
  },
  {
    accessor: 'status',
    label: 'Status',
    checkbox: false,
    sortable: false,
    editable: true,
    editType: 'select',
    options: ['OK', 'DNF'],
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 10,
    },
    style: {
      paddingLeft: 22,
    },
  },
  {
    accessor: 'buttons',
    label: '',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 10,
    },
    style: {
      ...defaultBodyStyle,
      paddingLeft: 22,
    },
  },
];

export const editFieldEventColumns = (attemptNumber) => {
  const columns = [
    {
      accessor: 'athleteNumber',
      label: 'Club ID',
      headerStyle: {
        fontSize: 13,
        paddingLeft: 21,
        minWidth: 154,
        letterSpacing: -0.3,
      },
      style: {
        fontSize: 13,
        paddingLeft: 21,
        minWidth: 154,
      },
    },
    {
      accessor: 'athleteName',
      label: 'Name',
      headerStyle: {
        paddingLeft: 20,
        paddingRight: 25,
        justifyContent: 'space-between',
      },
      style: {
        paddingLeft: 20,
      },
    },
  ];

  for (let index = 0; index < attemptNumber; index++) {
    columns.push({
      accessor: `attempt${index + 1}`,
      label: `Attempt ${index + 1}`,
      checkbox: false,
      sortable: false,
      editable: true,
      editType: 'attempt',
      headerStyle: {
        paddingLeft: 22,
        paddingRight: 5,
        minWidth: 58,
      },
      style: {
        paddingLeft: 22,
        paddingRight: 10,
      },
      options: attemptOptions,
    });
  }
  return columns;
};

export const editColumnPrimaryField = 'resultID';
