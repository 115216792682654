import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import layoutStyles from 'layouts/FormWithFooterLayout/FormWithFooterLayout.module.css';
import { getClubMemberByEmail, updateMember } from 'api/userApi';
import routes from 'routes';
import MemberForm from 'components/admin/MemberForm';
import Loading from 'components/common/Loading';

const EditMember = () => {
  const navigate = useNavigate();
  const { id, email } = useParams();

  const [loading, setLoading] = useState(false);
  const [fetchingMember, setFetchingMember] = useState(true);

  const [formValues, setFormValues] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    role: 'Member',
    participationStatus: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.firstName) newErrors.firstName = 'First name is required';
    if (!formValues.lastName) newErrors.lastName = 'Last name is required';
    if (!formValues.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = 'Email address is invalid';
    }
    if (!formValues.participationStatus)
      newErrors.participationStatus = 'Please select the participation status';

    return newErrors;
  };

  const fetchMember = useCallback(async () => {
    try {
      const response = await getClubMemberByEmail(id, email);
      console.log(response.data);

      setFormValues(response.data);
    } catch (err) {
      console.log('[EditMember] Fetch member error: ', err);
    }
  }, [id, email]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleRadioChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleButton = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      try {
        await updateMember(id, email, {
          email: formValues.email,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          role: formValues.role,
          participationStatus: formValues.participationStatus,
        });
        toast.success('Member updated successfully');
        navigate(routes.admin.members);
      } catch (err) {
        console.log(err);
        toast.error(err.response.data);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchMember().then(() => {
      setFetchingMember(false);
    });
  }, [fetchMember]);

  return fetchingMember ? (
    <Loading />
  ) : (
    <>
      <div className={layoutStyles.formContainer}>
        <div className={layoutStyles.sectionHeading}>
          Edit a <span>member</span>
        </div>
        <MemberForm
          type="edit"
          formValues={formValues}
          handleButton={handleButton}
          handleRadioChange={handleRadioChange}
          loading={loading}
          errors={errors}
          handleInputChange={handleInputChange}
        />
      </div>
    </>
  );
};

export default EditMember;
