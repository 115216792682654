export const dataTypeOptions = [
  { value: 'published', label: 'Published Results' },
  {
    value: 'pending',
    label: 'Pending Results',
    allowedRoles: ['Owner', 'Admin', 'Official'],
  },
];

export const columns = [
  {
    accessor: 'eventType',
    label: 'Event',
    checkbox: false,
    sortable: true,
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'gender',
    label: 'Gender',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 5,
      minWidth: 58,
    },
    style: {
      paddingLeft: 22,
      minWidth: 58,
    },
  },
  {
    accessor: 'ageGroup',
    label: 'Age Group',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 5,
      minWidth: 58,
    },
    style: {
      paddingLeft: 22,
      minWidth: 58,
    },
  },
  {
    accessor: 'roundType',
    label: 'Round Type',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 20,
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'roundNumber',
    label: 'Round Number',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 20,
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'manage',
    label: '',
    style: {
      fontSize: 11,
      backgroundColor: '#cf2c47',
      borderRadius: 3,
      color: '#fff',
      padding: '5px 15px 2px',
      fontWeight: 600,
      height: 'unset',
      width: 'fit-content',
      margin: 'auto',
    },
  },
];
