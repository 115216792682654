import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import routes from 'routes';
import {
  dataTypeOptions,
  viewTypeOptions,
  columns,
} from 'config/admin/competition';
import { getFilteredMeets } from 'api/clubApi';
import { getClubRole } from 'services/auth/tokenService';
import { meetsToTableData } from 'utils/meet';

import MetaTags from 'components/common/MetaTags';
import AdminTablePageLayout from 'components/admin/AdminTablePageLayout';
import AdminPageHeader from 'components/admin/AdminPageHeader';
import AdminPageOption from 'components/admin/AdminPageOption';
import AdminDataTable from 'components/admin/AdminDataTable';

const Competitions = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dataType = searchParams.get('dataType');
  const clubRole = getClubRole();

  const { selectedClub } = useSelector((state) => state.user || {});

  const [fetchingUpcomingMeets, setFetchingUpcomingMeets] = useState(true);
  const [fetchingCompleteMeets, setFetchingCompleteMeets] = useState(true);
  const [fetchingDraftMeets, setFetchingDraftMeets] = useState(true);

  const [meets, setMeets] = useState([]);
  const [upcomingMeets, setUpcomingMeets] = useState([]);
  const [completeMeets, setCompleteMeets] = useState([]);
  const [draftMeets, setDraftMeets] = useState([]);

  const [optionValues, setOptionValues] = useState({
    dataType: dataType || 'upcoming',
    viewType: 'table',
  });

  const handleOptionChange = useCallback(
    (name, value) => {
      setOptionValues({ ...optionValues, [name]: value });
      navigate(`${routes.admin.competitions}?${name}=${value}`);
    },
    [optionValues, navigate]
  );

  const fetchUpcomingMeets = useCallback(async () => {
    try {
      if (selectedClub) {
        const response = await getFilteredMeets(
          selectedClub?.clubID || '',
          ['Upcoming'],
          ['AdminOnly', 'ClubMembers']
        );

        console.log(response.data);
        setUpcomingMeets(meetsToTableData(response.data, selectedClub?.clubID));
      }
    } catch (err) {
      console.log('[Competitions] Fetch Upcoming Meets error: ', err);
    }
  }, [selectedClub]);

  const fetchCompleteMeets = useCallback(async () => {
    try {
      if (selectedClub) {
        const response = await getFilteredMeets(
          selectedClub?.clubID || '',
          ['Complete'],
          ['AdminOnly', 'ClubMembers']
        );

        console.log(response.data);
        setCompleteMeets(meetsToTableData(response.data, selectedClub?.clubID));
      }
    } catch (err) {
      console.log('[Competitions] Fetch Complete Meets error: ', err);
    }
  }, [selectedClub]);

  const fetchDraftMeets = useCallback(async () => {
    try {
      if (selectedClub && clubRole !== 'Member') {
        const response = await getFilteredMeets(
          selectedClub?.clubID || '',
          ['Draft'],
          ['AdminOnly', 'ClubMembers']
        );

        console.log(response.data);
        setDraftMeets(meetsToTableData(response.data, selectedClub?.clubID));
      }
    } catch (err) {
      console.log('[Competitions] Fetch Draft Meets error: ', err);
    }
  }, [selectedClub, clubRole]);

  useEffect(() => {
    fetchUpcomingMeets().then(() => {
      setFetchingUpcomingMeets(false);
    });
  }, [fetchUpcomingMeets]);

  useEffect(() => {
    fetchCompleteMeets().then(() => {
      setFetchingCompleteMeets(false);
    });
  }, [fetchCompleteMeets]);

  useEffect(() => {
    fetchDraftMeets().then(() => {
      setFetchingDraftMeets(false);
    });
  }, [fetchDraftMeets]);

  useEffect(() => {
    if (optionValues.dataType === 'upcoming') {
      setMeets(upcomingMeets);
    } else if (optionValues.dataType === 'complete') {
      setMeets(completeMeets);
    } else {
      setMeets(draftMeets);
    }
  }, [upcomingMeets, completeMeets, draftMeets, optionValues]);

  useEffect(() => {
    const findOption = dataTypeOptions.find(
      (option) => option.value === dataType
    );
    if (findOption) {
      if (
        findOption.allowedRoles &&
        findOption.allowedRoles.indexOf(clubRole) === -1
      )
        handleOptionChange('dataType', 'upcoming');
    } else {
      handleOptionChange('dataType', 'upcoming');
    }
  }, [dataType, clubRole, handleOptionChange]);

  return (
    <>
      <MetaTags title="SplitFast | Competitions" />
      <AdminTablePageLayout
        loading={
          fetchingCompleteMeets || fetchingDraftMeets || fetchingUpcomingMeets
        }
      >
        <AdminPageHeader
          number={meets.length}
          name="Competition"
          buttonLabel="+ Add a Competition"
          handleButton={
            clubRole === 'Member'
              ? null
              : () =>
                  navigate(
                    routes.admin.addCompetition.url(selectedClub?.clubID)
                  )
          }
        />
        <AdminPageOption
          clubRole={clubRole}
          dataTypeOptions={dataTypeOptions}
          viewTypeOptions={viewTypeOptions}
          optionValues={optionValues}
          handleOptionChange={handleOptionChange}
        />
        {meets.length ? (
          <AdminDataTable
            columns={columns}
            data={meets}
            searchInputPlaceholder="Search Competitions"
            headStyle={{
              gridTemplateColumns: '3fr 3fr 4fr 4fr 3fr 2fr 4fr',
            }}
            rowStyle={{
              gridTemplateColumns: '3fr 3fr 4fr 4fr 3fr 2fr 4fr',
            }}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>Competition not found</div>
        )}
      </AdminTablePageLayout>
    </>
  );
};

export default Competitions;
