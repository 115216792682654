const routes = {
  auth: {
    login: '/login',
    register: '/register',
    general: '/auth/general',
    plan: '/auth/plan',
    billing: '/auth/billing',
    setupClub: '/auth/setup-club',
    findClubs: '/auth/find-clubs',
    verifyEmail: '/api/auth/confirm-invitation',
    setPassword: '/set-password',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
  },
  client: {
    home: '/',
  },
  admin: {
    dashboard: '/dashboard',
    addUser: {
      path: '/clubs/:id/users/add',
      url: (id) => {
        return `/clubs/${id}/users/add`;
      },
    },
    events: {
      path: '/clubs/:clubId/meets/:meetId/events',
      url: (clubId, meetId) => {
        return `/clubs/${clubId}/meets/${meetId}/events`;
      },
    },
    results: {
      path: '/clubs/:clubId/meets/:meetId/events/:eventId/results',
      url: (clubId, meetId, eventId) => {
        return `/clubs/${clubId}/meets/${meetId}/events/${eventId}/results`;
      },
    },
    resultsAgg: {
      //DOUG added this to get aggregated results
      path: '/clubs/:clubId/meets/:meetId/results/:eventType/:ageGroup/:gender/:roundType',
      url: (clubId, meetId, eventType, ageGroup, gender, roundType) => {
        return `/clubs/${clubId}/meets/${meetId}/results/${eventType}/${ageGroup}/${gender}/${roundType}`;
      },
    },
    competitions: '/competitions',
    addCompetition: {
      path: '/clubs/:id/competitions/add',
      url: (id) => {
        return `/clubs/${id}/competitions/add`;
      },
    },
    editCompetition: {
      path: '/clubs/:clubId/competitions/:competitionId/edit',
      url: (clubId, competitionId) => {
        return `/clubs/${clubId}/competitions/${competitionId}/edit`;
      },
    },
    members: '/members',
    addMember: {
      path: '/clubs/:id/members/add',
      url: (id) => {
        return `/clubs/${id}/members/add`;
      },
    },
    editMember: {
      path: '/clubs/:id/members/:email/edit',
      url: (id, email) => {
        return `/clubs/${id}/members/${email}/edit`;
      },
    },
    showMember: {
      path: '/clubs/:id/members/:email/show',
      url: (id, email) => {
        return `/clubs/${id}/members/${email}/show`;
      },
    },
    athletes: '/athletes',
    addAthlete: {
      path: '/clubs/:id/members/:email/athletes/add',
      url: (id, email) => {
        return `/clubs/${id}/members/${email}/athletes/add`;
      },
    },
    editAthlete: {
      path: '/clubs/:id/members/:email/athletes/:athleteId/edit',
      url: (id, email, athleteId) => {
        return `/clubs/${id}/members/${email}/athletes/${athleteId}/edit`;
      },
    },
    notification: '/notifications',
    profile: '/profile',
  },
  notFound: '/404',
};

export default routes;
