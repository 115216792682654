import React from 'react';
import PropTypes from 'prop-types';

import { GENDER_OPTIONS } from 'constants';
import {
  ageGroupOptions,
  participationStatusOptions,
} from 'config/admin/athelete';
import NameInputBox from 'components/common/NameInputBox';
import CustomInput from 'components/common/CustomInput/CustomInput';
import CustomSelect from 'components/common/CustomSelect';
import CustomDatePicker from 'components/common/CustomDatePicker';
import CustomRadio from 'components/common/CustomRadio';
import CustomButton, {
  defaultButtonStyle,
} from 'components/common/CustomButton';
import LabelText from 'components/common/LabelText';

const AthleteForm = ({
  memberName,
  formValues,
  errors,
  loading,
  buttonLabel,
  handleInputChange,
  handleRadioChange,
  handleButton,
}) => {
  return (
    <>
      <LabelText
        label="Member"
        text={memberName}
        labelStyle={{
          color: '#24282a',
          fontWeight: 600,
          fontSize: 24,
        }}
        textStyle={{
          color: '#889398',
          fontWeight: 600,
          fontSize: 24,
        }}
        style={{ marginBottom: 40 }}
      />
      <NameInputBox
        label="Athlete Name"
        formValues={formValues}
        errors={errors}
        handleInputChange={handleInputChange}
      />
      <CustomSelect
        label="Gender"
        value={formValues.gender}
        onChange={handleInputChange}
        name="gender"
        options={GENDER_OPTIONS}
        error={errors.gender}
      />
      <CustomDatePicker
        label="Date of Birth"
        name="birthday"
        value={formValues.birthday}
        onChange={handleInputChange}
        error={errors.birthday}
      />
      <CustomRadio
        label="Age Group"
        options={ageGroupOptions}
        values={[formValues.ageGroup]}
        onChange={(value) => handleRadioChange('ageGroup', value)}
        error={errors.ageGroup}
      />
      <CustomInput
        label="Club ID Number"
        name="clubAthleteNumber"
        type="number"
        value={formValues.clubAthleteNumber}
        placeholder=""
        onChange={handleInputChange}
        error={errors.clubAthleteNumber}
      />
      <CustomSelect
        label="Participating"
        value={formValues.participationStatus}
        onChange={handleInputChange}
        name="participationStatus"
        options={participationStatusOptions}
        error={errors.participationStatus}
      />
      <CustomButton
        style={{
          ...defaultButtonStyle,
          maxWidth: 279,
          marginBottom: 49,
        }}
        onClick={handleButton}
        disabled={loading}
      >
        {buttonLabel}
      </CustomButton>
    </>
  );
};

AthleteForm.propTypes = {
  memberName: PropTypes.string,
  formValues: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleButton: PropTypes.func.isRequired,
};

export default AthleteForm;
