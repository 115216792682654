import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import layoutStyles from 'layouts/FormWithFooterLayout/FormWithFooterLayout.module.css';

import routes from 'routes';
import { createMeet } from 'api/clubApi';

import CompetitionForm from 'components/admin/CompetitionForm';

const AddCompetition = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    meetName: '',
    date: '',
    time: '',
    timezone: { id: 'UTC', displayName: 'UTC' },
    description: '',
    meetType: 1,
    location: '',
    ageGroups: [],
    multiLaneEvents: [],
    hurdleEvents: [],
    relayEvents: [],
    groupDistanceEvents: [],
    walkEvents: [],
    sprintAgilityTraining: [],
    fieldEvents: [],
    meetStatus: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.meetName) newErrors.meetName = 'Meent name is required';
    if (!formValues.date) newErrors.date = 'Date is required';
    if (!formValues.time) newErrors.time = 'Time is required';
    if (!formValues.description)
      newErrors.description = 'Description is required';
    if (!formValues.meetType)
      newErrors.meetType = 'Please select the meet type';
    if (!formValues.location) newErrors.location = 'Location is required';
    if (!formValues.ageGroups)
      newErrors.ageGroups = 'Please select the age groups';
    if (!formValues.meetStatus)
      newErrors.meetStatus = 'Please select the status';

    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleRadioChange = (name, value) => {
    setFormValues((prev) => {
      if (prev[name].includes(value)) {
        return {
          ...formValues,
          [name]: prev[name].filter((v) => v !== value),
        };
      } else {
        return {
          ...formValues,
          [name]: [...prev[name], value],
        };
      }
    });
  };

  const handleTimeChange = (time) => {
    setFormValues({
      ...formValues,
      time,
    });
  };

  const handleTimezoneChange = (timezone) => {
    setFormValues({
      ...formValues,
      timezone: {
        id: timezone,
        displayName: timezone,
      },
    });
  };

  const handleButton = async () => {
    const validationErrors = validateForm();
    console.log('formValues', formValues);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      try {
        const meetInfo = {
          clubID: id,
          meetName: formValues.meetName,
          meetType: formValues.meetType,
          meetDate: `${formValues.date}T${formValues.time}`,
          timeZone: formValues.timezone.id,
          location: formValues.location,
          description: formValues.location,
          meetStatus: formValues.meetStatus,
          publishingAccess: 'AdminOnly',
          ageGroups: formValues.ageGroups.join(','),
          eventTypes: [
            ...formValues.multiLaneEvents,
            ...formValues.hurdleEvents,
            ...formValues.relayEvents,
            ...formValues.groupDistanceEvents,
            ...formValues.walkEvents,
            ...formValues.sprintAgilityTraining,
            ...formValues.fieldEvents,
          ].join(','),
        };
        await createMeet(meetInfo);
        toast.success('Competition created successfully');
        navigate(routes.admin.competitions);
      } catch (err) {
        console.log(err);
        toast.error(err.response.data);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={layoutStyles.formContainer}>
      <div className={layoutStyles.sectionHeading}>
        Add a <span>Competition</span>
      </div>
      <CompetitionForm
        formValues={formValues}
        handleButton={handleButton}
        handleRadioChange={handleRadioChange}
        handleTimeChange={handleTimeChange}
        handleTimezoneChange={handleTimezoneChange}
        errors={errors}
        handleInputChange={handleInputChange}
        loading={loading}
      />
    </div>
  );
};

export default AddCompetition;
