import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import layoutStyles from 'layouts/FormWithFooterLayout/FormWithFooterLayout.module.css';
import routes from 'routes';
import { getAthlete, getClubMemberByEmail, updateAthlete } from 'api/userApi';
import MetaTags from 'components/common/MetaTags';
import AthleteForm from 'components/admin/AthleteForm';
import Loading from 'components/common/Loading';

const EditAthlete = () => {
  const navigate = useNavigate();
  const { id, email, athleteId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fetchingMember, setFetchingMember] = useState(true);
  const [member, setMember] = useState({});
  const [fetchingAthlete, setFetchingAthlete] = useState(true);

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    ageGroup: '5',
    birthday: '',
    participationStatus: 'YES',
    clubAthleteNumber: null,
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.firstName) newErrors.firstName = 'First name is required';
    if (!formValues.lastName) newErrors.lastName = 'Last name is required';

    if (!formValues.gender) newErrors.gender = 'Gender not selected';
    if (!formValues.ageGroup) newErrors.ageGroup = 'Age group not selected';
    if (!formValues.birthday) newErrors.birthday = 'Birthday is required';
    if (!formValues.clubAthleteNumber)
      newErrors.clubAthleteNumber = 'Club ID Number is required';

    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleRadioChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleButton = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      try {
        await updateAthlete(id, athleteId, {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          birthDate: formValues.birthday,
          participationStatus: formValues.participationStatus,
          ageGroup: +formValues.ageGroup,
          gender: formValues.gender,
          clubAthleteNumber: +formValues.clubAthleteNumber,
        });
        toast.success('Athlete updated successfully');
        navigate(routes.admin.showMember.url(id, email));
      } catch (err) {
        console.log(err);
        toast.error(err.response.data);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchMember = useCallback(async () => {
    try {
      const response = await getClubMemberByEmail(id, email);
      setMember(response.data);
    } catch (err) {
      console.log('[EditAthlete] Fetch Member error: ', err);
    }
  }, [id, email]);

  const fetchAthlete = useCallback(async () => {
    try {
      const response = await getAthlete(id, athleteId);
      console.log(response.data);
      setFormValues({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        gender: response.data.gender,
        ageGroup: response.data.ageGroup.toString(),
        birthday: response.data.birthDate,
        participationStatus: response.data.participationStatus.toUpperCase(),
        clubAthleteNumber: response.data.clubAthleteNumber.toString(),
      });
    } catch (err) {
      console.log('[EditAthlete] Fetch athlete error: ', err);
    }
  }, [id, athleteId]);

  useEffect(() => {
    fetchMember().then(() => setFetchingMember(false));
  }, [fetchMember]);

  useEffect(() => {
    fetchAthlete().then(() => setFetchingAthlete(false));
  }, [fetchAthlete]);

  return (
    <>
      <MetaTags title="SplitFast | Edit Athlete" />
      {fetchingMember || fetchingAthlete ? (
        <Loading />
      ) : (
        <div className={layoutStyles.formContainer}>
          <div className={layoutStyles.sectionHeading}>
            Edit an <span>athlete profile</span>
          </div>
          <AthleteForm
            memberName={`${member.firstName} ${member.lastName}`}
            formValues={formValues}
            handleButton={handleButton}
            handleRadioChange={handleRadioChange}
            loading={loading}
            errors={errors}
            buttonLabel="Save Changes"
            handleInputChange={handleInputChange}
          />
        </div>
      )}
    </>
  );
};

export default EditAthlete;
