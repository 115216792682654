import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Results.module.css';
import routes from 'routes';
import {
  getSelectedEvents,
  getAggResultsForEventType,
  getAggEventsByStatus,
  getEventsByStatus,
} from 'api/resultApi';
import { getMeetById } from 'api/clubApi';
import { fieldEventColumns, trackEventColumns } from 'config/admin/result';
import { convertDateTime } from 'utils/time';
import { getClubRole } from 'services/auth/tokenService';

import MetaTags from 'components/common/MetaTags';
import AdminPageHeader from 'components/admin/AdminPageHeader';
import AdminTablePageLayout from 'components/admin/AdminTablePageLayout';
import AdminDataTable from 'components/admin/AdminDataTable';
import AdminPageOption from 'components/admin/AdminPageOption';
import { defaultButtonStyle } from 'components/common/CustomButton';
import ResultEditPopup from 'components/admin/ResultEditPopup';

const Results = () => {
  const navigate = useNavigate();
  const clubRole = getClubRole();

  const { clubId, eventId, meetId, eventType, ageGroup, gender, roundType } =
    useParams();

  const [showEditPopup, setShowEditPopup] = React.useState(false);
  const [isPopupReady, setIsPopupReady] = useState(false);

  const [fetchingResults, setFetchingResults] = useState(true);
  const [fetchingEvent, setFetchingEvent] = useState(true);
  const [fetchingMeet, setFetchingMeet] = useState(true);
  const [fetchingEvents, setFetchingEvents] = useState(true);

  const [meet, setMeet] = useState(null);
  const [events, setEvents] = useState([]);
  const [, setEvent] = useState(null);
  const [results, setResults] = useState([]);
  const [resultType, setResultType] = useState('');

  const fetchResults = useCallback(async () => {
    try {
      setFetchingResults(true);

      const response = await getAggResultsForEventType(
        clubId,
        meetId,
        eventType,
        ageGroup,
        gender,
        roundType
      );

      console.log(response.data);
      const results = [];
      response.data.forEach((result) => {
        results.push({
          ...result,
          result: result.status === 'OK' ? result.resultValue : result.status,
          date: `${convertDateTime(result.timestamp || '').date} ${convertDateTime(result.timestamp || '').time}`,
          athleteName: `${result.athleteFirstName} ${result.athleteLastName}`,
          position: result.status === 'OK' ? result.position : '',
          attempt1:
            result.attempts?.length > 0
              ? result.attempts[0]
              : { distance: result.resultValue, status: 'Valid' },
          attempt2: result.attempts?.length > 1 ? result.attempts[1] : null,
          attempt3: result.attempts?.length > 2 ? result.attempts[2] : null,
          attempt4: result.attempts?.length > 3 ? result.attempts[3] : null,
          attempt5: result.attempts?.length > 4 ? result.attempts[4] : null,
          attempt6: result.attempts?.length > 5 ? result.attempts[5] : null,
          attempts: result?.attempts?.map((attempt, index) => (
            <div style={{ paddingLeft: 22, paddingRight: 10 }} key={index}>
              {attempt.resultValue}
            </div>
          )),
        });
      });
      setResults(results);
    } catch (err) {
      console.error('[Results] Fetching results Error: ', err);
    } finally {
      setFetchingResults(false);
    }
  }, [clubId, meetId, eventType, ageGroup, gender, roundType]);
  const fetchEvent = useCallback(async () => {
    try {
      const response = await getSelectedEvents(clubId, meetId, [eventId]);
      setEvent(response.data[0]);
    } catch (err) {
      console.log('[Results] Fetch Event error: ', err);
    }
  }, [clubId, meetId, eventId]);
  const fetchMeet = useCallback(async () => {
    try {
      const response = await getMeetById(clubId, meetId);

      setMeet(response.data);
    } catch (err) {
      console.log('[Results] Fetch Meets error: ', err);
    }
  }, [clubId, meetId]);
  const fetchEvents = useCallback(async () => {
    console.log('Trying to fetch Aggregated events');
    try {
      let fetchedEvents;
      let response = await getEventsByStatus(clubId, meetId, 'AdminOnly');
      console.log('Aggregated pending events: ', response.data);
      fetchedEvents = response.data;

      response = await getAggEventsByStatus(clubId, meetId, 'ClubMembers');

      fetchedEvents = [...fetchedEvents, ...response.data];

      console.log('Aggregated published events: ', response.data);

      setEvents(fetchedEvents);
    } catch (err) {
      console.error('[Results] Fetch events error: ', err);
    }
  }, [clubId, meetId]);

  const firstResult = results.find((result) => result.position === 1);

  useEffect(() => {
    if (results.length > 0) {
      setIsPopupReady(true);
    }
  }, [results]);

  useEffect(() => {
    fetchResults().then(() => {
      setFetchingResults(false);
    });
  }, [fetchResults]);

  useEffect(() => {
    fetchEvent().then(() => {
      setFetchingEvent(false);
    });
  }, [fetchEvent]);

  useEffect(() => {
    fetchMeet().then(() => {
      setFetchingMeet(false);
    });
  }, [fetchMeet]);

  useEffect(() => {
    fetchEvents().then(() => {
      setFetchingEvents(false);
    });
  }, [fetchEvents]);

  useEffect(() => {
    console.log('events', events);
    if (events) {
      const event = events.find(
        (event) =>
          event.eventType === eventType &&
          event.gender === gender &&
          event.ageGroup === ageGroup &&
          event.roundType === roundType
      );

      if (event) {
        if (event.publishingStatus === 'ClubMembers')
          setResultType('Published');
        else setResultType('Pending');
      }
    }
  }, [events, eventType, ageGroup, gender, roundType]);

  return (
    <>
      <MetaTags title="SplitFast | Results" />
      <AdminTablePageLayout
        loading={
          fetchingMeet || fetchingEvent || fetchingResults || fetchingEvents
        }
      >
        <AdminPageHeader
          title={
            eventType && ageGroup && gender && roundType
              ? `Results for ${gender} ${ageGroup} ${eventType} ${roundType}`
              : 'Results'
          }
          showNumber={false}
          numberText={`${results.length} Published, 0 Pending`}
          name="Result"
          buttonLabel={
            meet
              ? `${meet?.meetName} - ${convertDateTime(meet?.meetDate || '').date}`
              : 'Go to event'
          }
          buttonStyle={{
            color: '#24282a',
            fontWeight: 600,
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            height: 'unset',
          }}
          handleButton={() => {
            navigate(routes.admin.events.url(clubId, meetId));
          }}
          style={{
            marginBottom: 0,
          }}
        />
        <div className={styles.subtitle}>
          Club record: {firstResult?.athleteFirstName}{' '}
          {firstResult?.athleteLastName} {firstResult?.resultValue} -{' '}
          {convertDateTime(meet?.meetDate).date}
        </div>
        <AdminPageOption
          hasButton={clubRole !== 'Member'}
          buttonLabel="Edit Results"
          buttonStyle={{
            ...defaultButtonStyle,
            marginBottom: 0,
            height: 'unset',
            padding: '10px 20px',
          }}
          buttonDisabled={!isPopupReady}
          handleButton={() => setShowEditPopup(true)}
        />
        {results.length ? (
          <AdminDataTable
            borderType="full"
            isAddedFeatures={false}
            columns={
              results[0]?.unit === 'm'
                ? fieldEventColumns(results[0]?.attempts.length)
                : trackEventColumns
            }
            data={results}
            searchInputPlaceholder=""
            headStyle={{
              gridTemplateColumns:
                results[0]?.unit === 'm'
                  ? `1fr 4fr 2fr 2fr 2fr 2fr 2fr ${2 * results[0]?.attempts?.length}fr`
                  : '2fr 4fr 3fr 3fr 3fr 3fr 3fr 4fr',
              background: '#f1f1f2',
            }}
            rowStyle={{
              gridTemplateColumns:
                results[0]?.unit === 'm'
                  ? `1fr 4fr 2fr 2fr 2fr 2fr 2fr ${2 * results[0]?.attempts?.length}fr`
                  : '2fr 4fr 3fr 3fr 3fr 3fr 3fr 4fr',
            }}
          />
        ) : null}
      </AdminTablePageLayout>
      {clubRole !== 'Member' && (
        <ResultEditPopup
          resultType={resultType}
          results={results}
          eventID={eventId}
          clubID={clubId}
          meetID={meetId} // DOUG - Pass additional parameters for aggregated results
          eventType={eventType}
          ageGroup={ageGroup}
          gender={gender}
          roundType={roundType}
          title={
            eventType && ageGroup && gender && roundType
              ? `Results for ${gender} ${ageGroup} ${eventType} ${roundType}`
              : 'Results'
            //event
            //? `Results for ${event.gender} ${event.ageGroup} ${event.eventType} ${event.roundType}`
            //: 'Results'
          }
          subtitle={meet ? convertDateTime(meet?.meetDate || '').date : ''}
          showPopup={showEditPopup && results.length > 0}
          closePopup={() => setShowEditPopup(false)}
          refreshResults={fetchResults}
        />
      )}
    </>
  );
};

export default Results;
