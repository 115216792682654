import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import layoutStyles from 'layouts/FormWithFooterLayout/FormWithFooterLayout.module.css';

import routes from 'routes';
import { createClubMember } from 'api/userApi';

import MemberForm from 'components/admin/MemberForm';

const AddMember = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: 'Member',
    sendInvitation: false,
    participationStatus: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.firstName) newErrors.firstName = 'First name is required';
    if (!formValues.lastName) newErrors.lastName = 'Last name is required';
    if (!formValues.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = 'Email address is invalid';
    }

    if (!formValues.participationStatus)
      newErrors.participationStatus = 'Please select a participation status';

    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (name) => {
    setFormValues({ ...formValues, [name]: !formValues[name] });
  };

  const handleRadioChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleButton = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      try {
        await createClubMember({
          email: formValues.email,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          clubID: id,
          role: formValues.role,
          participationStatus: formValues.participationStatus,
          sendInvitation: formValues.sendInvitation,
        });
        toast.success('Member created successfully');
        navigate(routes.admin.members);
      } catch (err) {
        console.log(err);
        toast.error(err.response.data);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={layoutStyles.formContainer}>
      <div className={layoutStyles.sectionHeading}>
        Add a <span>member</span>
      </div>
      <MemberForm
        formValues={formValues}
        loading={loading}
        errors={errors}
        handleButton={handleButton}
        handleRadioChange={handleRadioChange}
        handleInputChange={handleInputChange}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  );
};

export default AddMember;
