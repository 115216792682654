import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import layoutStyles from 'layouts/FormWithFooterLayout/FormWithFooterLayout.module.css';

import routes from 'routes';
import { getMeetById, updateMeet } from 'api/clubApi';

import Loading from 'components/common/Loading';
import CompetitionForm from 'components/admin/CompetitionForm';

const EditCompetition = () => {
  const navigate = useNavigate();

  const { clubId, competitionId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fetchingMeet, setFetchingMeet] = useState(true);

  const [formValues, setFormValues] = useState({
    meetName: '',
    date: '',
    time: '',
    description: '',
    meetType: 1,
    location: '',
    ageGroups: [],
    multiLaneEvents: [],
    hurdleEvents: [],
    relayEvents: [],
    groupDistanceEvents: [],
    walkEvents: [],
    sprintAgilityTraining: [],
    fieldEvents: [],
    meetStatus: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.meetName) newErrors.meetName = 'Meent name is required';
    if (!formValues.date) newErrors.date = 'Date is required';
    if (!formValues.time) newErrors.time = 'Time is required';
    if (!formValues.description)
      newErrors.description = 'Description is required';
    if (!formValues.meetType)
      newErrors.meetType = 'Please select the meet type';
    if (!formValues.location) newErrors.location = 'Location is required';
    if (!formValues.ageGroups)
      newErrors.ageGroups = 'Please select the age groups';
    if (!formValues.meetStatus)
      newErrors.meetStatus = 'Please select the status';

    return newErrors;
  };

  const fetchMeet = useCallback(async () => {
    try {
      const response = await getMeetById(clubId, competitionId);
      console.log('meet: ', response.data);
      console.log('meetStatus: ', response.data.meetStatus);

      setFormValues({
        meetName: response.data.meetName,
        date: response.data.meetDate.split('T')[0],
        time: `${response.data.meetDate.split('T')[1].split(':')[0]}:${response.data.meetDate.split('T')[1].split(':')[1]}`,
        timezone: response.data.timeZone
          ? { id: response.data.timeZone, displayName: response.data.timeZone }
          : { id: 'UTC', displayName: 'UTC' },
        description: response.data.description,
        meetType: response.data.meetType.toString(),
        location: response.data.location,
        ageGroups:
          !response.data.ageGroups || response.data.ageGroups === ''
            ? []
            : response.data.ageGroups.split(','),
        multiLaneEvents: response.data.eventTypes
          .split(',')
          .filter(
            (item) =>
              (+item >= 20 && +item <= 22) || item === '24' || item === '25'
          ),
        hurdleEvents: response.data.eventTypes
          .split(',')
          .filter((item) => item === '23' || item === '26' || item === '29'),
        relayEvents: response.data.eventTypes
          .split(',')
          .filter(
            (item) =>
              item === '27' || item === '28' || item === '34' || item === '35'
          ),
        groupDistanceEvents: response.data.eventTypes
          .split(',')
          .filter((item) => +item >= 31 && +item <= 33),
        walkEvents: response.data.eventTypes
          .split(',')
          .filter((item) => +item >= 41 && +item <= 45),
        sprintAgilityTraining: response.data.eventTypes
          .split(',')
          .filter((item) => +item >= 1 && +item <= 5),
        fieldEvents: response.data.eventTypes
          .split(',')
          .filter((item) => +item >= 61 && +item <= 66),
        meetStatus: response.data.meetStatus,
      });
    } catch (err) {
      console.log('[EditCompetition] Fetch meet error: ', err);
      if (err.status === 404) {
        navigate(routes.notFound);
      } else {
        toast.error(err.message);
        navigate(routes.admin.dashboard);
      }
    }
  }, [clubId, competitionId, navigate]);

  useEffect(() => {
    fetchMeet().then(() => {
      setFetchingMeet(false);
    });
  }, [fetchMeet]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleRadioChange = (name, value) => {
    setFormValues((prev) => {
      if (prev[name].includes(value)) {
        return {
          ...formValues,
          [name]: prev[name].filter((v) => v !== value),
        };
      } else {
        return {
          ...formValues,
          [name]: [...prev[name], value],
        };
      }
    });
  };

  const handleTimeChange = (time) => {
    setFormValues({
      ...formValues,
      time,
    });
  };

  const handleTimezoneChange = (timezone) => {
    setFormValues({
      ...formValues,
      timezone: {
        id: timezone,
        displayName: timezone,
      },
    });
  };

  const handleButton = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      try {
        const meetInfo = {
          meetName: formValues.meetName,
          meetType: formValues.meetType,
          meetDate: `${formValues.date}T${formValues.time}`,
          timeZone: formValues.timezone.id,
          location: formValues.location,
          description: formValues.description,
          meetStatus: formValues.meetStatus,
          ageGroups: formValues.ageGroups.join(','),
          eventTypes: [
            ...formValues.multiLaneEvents,
            ...formValues.hurdleEvents,
            ...formValues.relayEvents,
            ...formValues.groupDistanceEvents,
            ...formValues.walkEvents,
            ...formValues.sprintAgilityTraining,
            ...formValues.fieldEvents,
          ].join(','),
        };
        await updateMeet(competitionId, meetInfo);
        toast.success('Competition updated successfully');
        navigate(routes.admin.competitions);
      } catch (err) {
        console.log(err);
        toast.error(err.response.data);
      } finally {
        setLoading(false);
      }
    }
  };

  return fetchingMeet ? (
    <Loading />
  ) : (
    <div className={layoutStyles.formContainer}>
      <div className={layoutStyles.sectionHeading}>
        Edit the <span>Competition</span>
      </div>
      <CompetitionForm
        formValues={formValues}
        handleButton={handleButton}
        handleRadioChange={handleRadioChange}
        handleTimeChange={handleTimeChange}
        handleTimezoneChange={handleTimezoneChange}
        errors={errors}
        handleInputChange={handleInputChange}
        loading={loading}
      />
    </div>
  );
};

export default EditCompetition;
