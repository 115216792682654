import authApi from './index';

/**
 * Fetch the result highlights for a specific club by sending a GET request to the '/results/get-result-highlights' endpoint.
 * It takes the club ID and the number of highlights to fetch (default is 8).
 *
 * @param {string} clubId - The ID of the club whose result highlights are to be fetched.
 * @param {number} numberOfHighlights - The number of result highlights to fetch (default is 8).
 * @returns {Promise} - The response from the API containing the result highlights.
 */
export const getResultHighlights = async (clubId, numberOfHighlights = 8) => {
  return await authApi.get(
    `/results/get-result-highlights?clubId=${clubId}&numberOfHighlights=${numberOfHighlights}`
  );
};

/**
 * Fetch the list of completed events for a specific club and meet by sending a GET request to the '/results/get-events' endpoint.
 * Optionally, you can pass the 'lastUpdated' parameter to filter events updated after a specific time.
 *
 * @param {string} clubId - The ID of the club whose completed events are to be fetched.
 * @param {string} meetId - The ID of the meet whose completed events are to be fetched.
 * @param {string|null} lastUpdated - The optional timestamp to filter events by their last update time.
 * @returns {Promise} - The response from the API containing the list of completed events.
 */
export const getCompletedEvents = async (
  clubId,
  meetId,
  lastUpdated = null
) => {
  if (!lastUpdated) {
    return await authApi.get(
      `/results/get-events?clubId=${clubId}&meetId=${meetId}`
    );
  } else {
    return await authApi.get(
      `/results/get-events?clubId=${clubId}&meetId=${meetId}&lastUpdated=${lastUpdated}`
    );
  }
};

/**
 * Fetch the results for specific events by sending a POST request to the '/results/get-event-results' endpoint.
 * The request includes a list of event IDs, the club ID, and an optional timestamp to filter results updated after a specific time.
 *
 * @param {Array<string>} eventIds - An array of event IDs for which results are to be fetched.
 * @param {string} clubId - The ID of the club associated with these events.
 * @param {string|null} since - An optional timestamp to filter results by their last update time.
 * @returns {Promise} - The response from the API containing the results for the specified events.
 */
export const getResultsForEvents = async (eventIds, clubId, since = null) => {
  return await authApi.post('/results/get-event-results', {
    eventIds,
    clubId,
    since,
  });
};

// DOUG - Added this to fetch aggregated results matching an event type and age group query
/**
 * Fetches aggregate event results.
 *
 * This function retrieves aggregated results for a specific club, meet, event type, age group, gender, and round type.
 *
 * @param {string} clubID - The unique identifier of the club.
 * @param {string} meetID - The unique identifier of the meet.
 * @param {string} eventType - The type of the event (e.g., "100m", "Relay").
 * @param {string} ageGroup - The age group for the event (e.g., "U14").
 * @param {string} gender - The gender for the event (e.g., "Male", "Female").
 * @param {string} roundType - The round type for the event (e.g., "Heat", "Final").
 * @returns {Promise<Object>} - A Promise that resolves to the API response containing the event results.
 */
export const getAggResultsForEventType = async (
  clubID,
  meetID,
  eventType,
  ageGroup,
  gender,
  roundType
) => {
  return await authApi.post(`/events/get-agg-event-results`, {
    clubID,
    meetID,
    eventType,
    ageGroup,
    gender,
    roundType,
  });
};

/**
 * Fetches details of selected events for a specific club and meet.
 *
 * This function retrieves information about specific events for a given club and meet.
 * Optionally, it filters the results based on a `lastUpdated` timestamp.
 *
 * @param {string} clubId - The unique identifier of the club.
 * @param {string} meetId - The unique identifier of the meet.
 * @param {Array<string>} eventIds - An array of event IDs to fetch details for.
 * @param {string|null} lastUpdated - An optional timestamp to filter events by their last update date (format: YYYY-MM-DDTHH:mm:ss).
 * @returns {Promise<Object>} - A Promise that resolves to the API response containing details of the selected events.
 */
export const getSelectedEvents = async (
  clubId,
  meetId,
  eventIds,
  lastUpdated = null
) => {
  return await authApi.post(`/results/get-selected-events`, {
    clubId,
    meetId,
    eventIds,
    lastUpdated,
  });
};

/**
 * Updates results for specific events.
 *
 * This function sends updated event results to the API for modification.
 *
 * @param {Object} results - An object containing the results data to be updated.
 * @returns {Promise<Object>} - A Promise that resolves to the API response after updating the results.
 */
export const updateResults = async (results) => {
  return await authApi.put(`/results/edit`, results);
};

/**
 * Updates attempt results.
 *
 * This function sends a PUT request to the `/results/update-attempt-results` endpoint
 * to update multiple attempt results with the provided array of data.
 *
 * @param {Array<Object>} results - An array of objects, where each object represents
 *   an attempt result to be updated. Each object should contain the necessary data
 *   for the update (e.g., result ID, score, etc.).
 * @returns {Promise<Object>} - A promise that resolves with the response from the server.
 *
 * Example usage:
 * const updatedResults = await updateAttemptResults([
 *   { id: '123', score: 95 },
 *   { id: '124', score: 88 }
 * ]);
 */
export const updateAttemptResults = async (results) => {
  return await authApi.put(`/results/update-attempt-results`, results);
};

/**
 * Fetches events based on their publishing status.
 *
 * This function retrieves events for a specific club and meet, filtered by their publishing status.
 * Optionally, it filters events updated after a specific timestamp.
 *
 * @param {string} clubID - The unique identifier of the club.
 * @param {string} meetID - The unique identifier of the meet.
 * @param {string} publishingStatus - The publishing status of the events (e.g., "ClubMembers", "AdminOnly").
 * @param {string|null} lastUpdated - An optional timestamp to filter events by their last update date (format: YYYY-MM-DDTHH:mm:ss).
 * @returns {Promise<Object>} - A Promise that resolves to the API response containing the filtered events.
 */
export const getEventsByStatus = async (
  clubID,
  meetID,
  publishingStatus,
  lastUpdated = null
) => {
  return await authApi.post(`/results/get-pubstatus-events`, {
    clubID,
    meetID,
    publishingStatus,
    lastUpdated,
  });
};

// DOUG - Adding Fetch aggregated Events function
/**
 * Fetches aggregated events based on their publishing status.
 *
 * This function retrieves events for a specific club and meet, filtered by their publishing status.
 * The events are aggregated by event type and agegroup. For exaxmple, if there are multiple Heats for a single event,
 * they will be aggregated into a single event.
 *
 * @param {string} clubID - The unique identifier of the club.
 * @param {string} meetID - The unique identifier of the meet.
 * @param {string} publishingStatus - The publishing status of the events (e.g., "ClubMembers", "AdminOnly").
 * @returns {Promise<Object>} - A Promise that resolves to the API response containing the filtered events.
 */
export const getAggEventsByStatus = async (
  clubID,
  meetID,
  publishingStatus
) => {
  return await authApi.post(`/events/get-agg-events`, {
    clubID,
    meetID,
    publishingStatus,
  });
};

/**
 * Updates the status of specific events.
 *
 * This function sends a request to update the statuses of a list of events.
 *
 * @param {Array<Object>} events - An array of event objects, each containing event details and the updated status.
 * @returns {Promise<Object>} - A Promise that resolves to the API response after updating the event statuses.
 */
export const updateEventStatus = async (events) => {
  return await authApi.put(`/results/event-status`, events);
};

//DOUG - added this for updating aggregated events
/**
 * Updates the publishing status of aggregated events.
 *
 * This function sends a request to update the publishing status for events matching the specified parameters.
 *
 * @param {Object} params - The parameters for the request, including clubID, meetID, eventType, ageGroup, gender, roundType, and newPublishingStatus.
 * @returns {Promise<Object>} - A Promise that resolves to the API response after updating the publishing status.
 */
export const updateAggEventStatus = async (params) => {
  return await authApi.put(`/events/agg-event-status`, params);
};
