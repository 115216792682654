import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './EditableInput.module.css';

const EditableInput = ({ value, inputValueChange }) => {
  const [tempValue, setTempValue] = useState(value);

  const handleInputChange = (e) => {
    setTempValue(e.target.value);
  };

  const handleInputBlur = () => {
    const formattedValue = parseFloat(tempValue).toFixed(2);
    setTempValue(formattedValue);
    inputValueChange(formattedValue);
  };

  useEffect(() => {
    setTempValue(parseFloat(value).toFixed(2));
  }, [value]);

  return (
    <input
      className={styles.cellInput}
      value={tempValue}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      type="number"
      min="0"
      step="0.01"
    />
  );
};

EditableInput.propTypes = {
  value: PropTypes.string,
  inputValueChange: PropTypes.func,
};

export default EditableInput;
