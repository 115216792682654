import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './Dashboard.module.css';

import routes from 'routes';
import { getRecentMeets, getUpcomingMeets } from 'api/clubApi';
import { getResultHighlights } from 'api/resultApi';

import DashboardNotification from 'components/admin/DashboardNotification';
import AdminBlock from 'components/admin/AdminBlock';
import AdminTable from 'components/admin/AdminTable';
import EventCard from 'components/admin/EventCard';
import Loading from 'components/common/Loading';
import MetaTags from 'components/common/MetaTags';

import upcomingEventImage1 from 'assets/images/uifb.png';
import upcomingEventImage2 from 'assets/images/uifd.png';
import upcomingEventImage3 from 'assets/images/uijh.png';
import upcomingEventImage4 from 'assets/images/uiqw.png';
import MedalBadge from 'assets/images/icon_medal_badge.png';

import { convertDateTime } from 'utils/time';
import {
  competitionTableColumns,
  trainingSessionTableColumns,
  highlightTableColumns,
  competitionTableRowLimit,
  trainingSessionTableRowLimit,
  upcomingTableRowLimit,
  resultHighlightsTableRowLimit,
} from 'config/admin/dashboard';
import { getUserInfo } from 'services/auth/tokenService';

const Dashboard = () => {
  const userInfo = JSON.parse(getUserInfo());

  const { selectedClub } = useSelector((state) => state.user || {});

  const [fetchingRecentMeets, setFetchingRecentMeets] = useState(true);
  const [fetchingUpcomingMeets, setFetchingUpcomingMeets] = useState(true);
  const [fetchingHighlight, setFetchingHighlight] = useState(true);

  const [competitionData, setCompetitionData] = useState([]);
  const [trainingData, setTrainingData] = useState([]);
  const [upcomingMeets, setUpcomingMeets] = useState([]);
  const [highlight, setHighlight] = useState([]);

  const fetchRecentMeets = useCallback(async () => {
    try {
      if (selectedClub) {
        const response = await getRecentMeets(selectedClub?.clubID || '');
        const meets = response.data;

        const recentCompetitions = [];
        const recentTrainingSessions = [];

        console.log('meets: ', meets);

        meets.forEach((meet) => {
          if (
            meet.meetType === 1 &&
            recentCompetitions.length < competitionTableRowLimit
          ) {
            recentCompetitions.push({
              ...meet,
              date: convertDateTime(meet.meetDate).date,
              time: convertDateTime(meet.meetDate).time,
              buttons: (
                <Link
                  to={routes.admin.events.url(meet.clubID, meet.meetID)}
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  Results
                </Link>
              ),
            });
          }
          if (
            meet.meetType === 0 &&
            recentTrainingSessions.length < trainingSessionTableRowLimit
          ) {
            recentTrainingSessions.push({
              ...meet,
              date: convertDateTime(meet.meetDate).date,
              time: convertDateTime(meet.meetDate).time,
              buttons: (
                <Link
                  to={routes.admin.events.url(meet.clubID, meet.meetID)}
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  Results
                </Link>
              ),
            });
          }
        });
        setCompetitionData(recentCompetitions);
        setTrainingData(recentTrainingSessions);
      }
    } catch (err) {
      console.log('[Dashboard] Fetch Meets error: ', err);
    }
  }, [selectedClub]);

  const fetchUpcomingMeets = useCallback(async () => {
    try {
      if (selectedClub) {
        const response = await getUpcomingMeets(selectedClub?.clubID || '');

        const upcomingMeets = [];
        response.data.forEach((meet, index) => {
          if (upcomingMeets.length < upcomingTableRowLimit) {
            let image;
            switch (index % 4) {
              case 1:
                image = upcomingEventImage1;
                break;

              case 2:
                image = upcomingEventImage3;
                break;

              case 3:
                image = upcomingEventImage4;
                break;

              default:
                image = upcomingEventImage2;
            }
            upcomingMeets.push({
              ...meet,
              date: convertDateTime(meet.meetDate).date,
              time: convertDateTime(meet.meetDate).time,
              image,
            });
          }
          setUpcomingMeets(upcomingMeets);
        });
      }
    } catch (err) {
      console.log('[Dashboard] Fetch Upcoming Meets error: ', err);
    }
  }, [selectedClub]);

  const fetchHighlight = useCallback(async () => {
    try {
      if (selectedClub) {
        const response = await getResultHighlights(
          selectedClub?.clubID || '',
          resultHighlightsTableRowLimit
        );

        const highlights = [];

        response.data.forEach((highlight) => {
          highlights.push({
            ...highlight,
            date: `${convertDateTime(highlight.resultDate).date}`,
            url: (
              <Link
                to={routes.admin.results.url(
                  selectedClub?.clubID,
                  highlight.meetId,
                  highlight.eventId
                )}
                style={{ textDecoration: 'none', color: '#fff' }}
              >
                View Event
              </Link>
            ),
            isPR: highlight.isPR ? (
              <img src={MedalBadge} style={{ width: '30px' }} alt="PR" />
            ) : null,
          });
        });

        setHighlight(highlights);
      }
    } catch (err) {
      console.log('[Dashboard] Fetch Highlight error: ', err);
    }
  }, [selectedClub]);

  useEffect(() => {
    fetchRecentMeets().then(() => {
      setFetchingRecentMeets(false);
    });
  }, [fetchRecentMeets]);

  useEffect(() => {
    fetchUpcomingMeets().then(() => {
      setFetchingUpcomingMeets(false);
    });
  }, [fetchUpcomingMeets]);

  useEffect(() => {
    fetchHighlight().then(() => {
      setFetchingHighlight(false);
    });
  }, [fetchHighlight]);

  return fetchingRecentMeets || fetchingUpcomingMeets || fetchingHighlight ? (
    <>
      <Loading isLoadingAdminPage={true} />
    </>
  ) : (
    <>
      <MetaTags title="SplitFast | Dashboard" />
      <div className={styles.pageContainer}>
        <div className={styles.title}>Dashboard</div>
        <DashboardNotification user={userInfo} notificationNumber={0} />
        <div className={styles.competitionTraining}>
          <div className={styles.competitionTrainingWrapper}>
            <AdminBlock
              title="Recent Competitions"
              url={routes.admin.dashboard}
            >
              <AdminTable
                columns={competitionTableColumns}
                data={competitionData}
                rowStyle={{
                  display: 'grid',
                  gridTemplateColumns: '1143fr 650fr 1469fr 990fr 430fr',
                }}
              />
            </AdminBlock>
            <AdminBlock
              title="Recent Training Sessions"
              url={routes.admin.dashboard}
            >
              <AdminTable
                columns={trainingSessionTableColumns}
                data={trainingData}
                nameStyle={{ fontWeight: '400' }}
                rowStyle={{
                  display: 'grid',
                  gridTemplateColumns: '1300fr 1090fr 1170fr 1240fr 500fr',
                }}
              />
            </AdminBlock>
          </div>
        </div>
        <div className={styles.resultHighlights}>
          <div className={styles.resultHighlightsWrapper}>
            <AdminBlock title="Result Highlights">
              <AdminTable
                columns={highlightTableColumns}
                data={highlight}
                rowStyle={{
                  display: 'grid',
                  gridTemplateColumns: '7fr 7fr 7fr 7fr 7fr 4fr 3fr 8fr',
                  alignItems: 'center',
                  padding: '22px 13px 26px 22px',
                }}
              />
            </AdminBlock>
          </div>
        </div>
        <div className={styles.upcomingPas}>
          <div className={styles.upcomingPasWrapper}>
            <div className={styles.upcomingWrapper}>
              <AdminBlock title="Upcoming Meets" url={routes.admin.dashboard}>
                <div className={styles.upcomingCards}>
                  {upcomingMeets.map((event, index) => (
                    <EventCard
                      key={index}
                      event={event}
                      clubId={selectedClub?.clubID}
                    />
                  ))}
                </div>
              </AdminBlock>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
